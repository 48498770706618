import React, { Fragment, useEffect, useState } from "react";
import dateIcon from "../assets/images/date-icon.svg";
import { Link } from "react-router-dom";
import rightArrow from "../assets/images/right-arrow.svg";
import { getHTMLdateFormat } from "../component/common";
import Wallet from "../assets/images/wallet.svg";
import Previous from "../assets/images/previous.svg";
import { getUpdateProfile, getUserProfileLite } from "../apis";
import { constants } from "../constants/constants";

function DobNState(props) {
  const [gameName, setGameName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [clickSubmit, setClickSubmit] = useState(false);

  useEffect(() => {
    fetchData();
  });

  const fetchData = () => {
    let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
    var count = allpage.findIndex((d) => d === window.location.pathname);
    count === -1 && allpage.push(window.location.pathname);
    localStorage.setItem("currentPage", JSON.stringify(allpage));
  };

  const onSubmit = async () => {
    setClickSubmit(true);
    if (gameName !== "") {
      let allgameNames = JSON.parse(localStorage.getItem(constants.profileLite))
        ?.item?.gameNames;
      allgameNames.push({
        game: localStorage.getItem("activeGameId"),
        uniqueIGN: gameName,
      });
      let data = {
        gameNames: allgameNames,
      };
      if (dateOfBirth != "") {
        data.dateOfBirth = dateOfBirth;
      }
      localStorage.setItem("uniqueIGName", gameName);
      let res = await getUpdateProfile(data);
      if (res) {
        let profileLiteResponse = await getUserProfileLite();
        if(profileLiteResponse){
          localStorage.setItem(constants.profileLite,JSON.stringify(profileLiteResponse));
        }

      }
      props.dobnstate(false);
    }
  };

  const gobacktoHome = () => {
    props.dobnstate(false);
  };
  // ?.dateOfBirth );
  return (
    <div className="inner-wrap">
      <div className="header">
        <div className="back-btn">
          {/* <a onClick={() => gobacktoHome()}>
            <img src={Previous} alt="" />
          </a> */}
        </div>
        <h2>DOB & STATE</h2>
        <div className="wallet-btn">
          {/* <Link to="#">
            <img src={Wallet} alt="" />
            Wallet
          </Link> */}
        </div>
      </div>
      <div className="body mCustomScrollbar _mCS_1">
        <div className="form-group">
          <label>Gamerji User Name</label>
          <input
            type="text"
            className="form-control"
            value={
              JSON.parse(localStorage.getItem(constants.profileLite))?.item
                ?.gamerjiName
            }
            disabled
          />
        </div>

        <div className="form-group">

          <label>What's your date of birth? </label>
          <div className="date-field">
            <div className="icon">
              <img src={dateIcon} alt="" />
            </div>
            {
              JSON.parse(localStorage.getItem(constants.profileLite))?.item
                ?.dateOfBirth === undefined ?
                <input
                  type="date"
                  className="form-control datepicker"

                  onChange={(e) => setDateOfBirth(e.target.value)}

                />
                : <input
                  type="date"
                  className="form-control datepicker"
                  value={getHTMLdateFormat(
                    JSON.parse(localStorage.getItem(constants.profileLite))?.item
                      ?.dateOfBirth
                  )}
                  disabled
                />
            }
          </div>
        </div>
        {JSON.parse(localStorage.getItem(constants.profileLite))?.item
          ?.country?.code == "IN" ?
          <div className="form-group">
            <label>Where are you from?</label>
            <select
              className="select form-control"
              disabled
              value={
                JSON.parse(localStorage.getItem(constants.profileLite))?.item
                  ?.state?.name
              }
            >
              <option>GUJARAT</option>
              <option>DELHI</option>
              <option>MUMBAI</option>
              <option>PUNE</option>
            </select>
          </div> : ""}
        <div className="form-group">
          <label>Your {localStorage.getItem("activeGame")} Name</label>
          <input
            type="text"
            placeholder={`Enter your ${localStorage.getItem(
              "activeGame"
            )} name`}
            className="form-control"
            value={gameName}
            onChange={(e) => setGameName(e.target.value)}
          />
          {gameName === "" && clickSubmit && (
            <span className="error">Please enter game name</span>
          )}
        </div>

        <button className="btn btn-dark main-btn" onClick={() => onSubmit()}>
          SUBMIT <img src={rightArrow} alt="" className="mCS_img_loaded" />
        </button>
      </div>
    </div>
  );
}

export default DobNState;
