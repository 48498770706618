import React, { useState } from "react";
import Close from "../../assets/images/close.svg";
import { useTranslation } from "react-i18next";
import { createPayPayment } from "../../apis";
import PurchasePackIcon from "../../assets/images/purchase-pack-icon.png";
import PremiumClose from "../../assets/images/cancel_premium.png";
import PremiumLogo from '../../assets/images/GoldPremium.png'
import { url } from "../../constants";
import Coin from "../../assets/images/coin.png";

const PayUForm = ({ setShowPayuForm, payload, isSubscription, isBundle , bundlesData, setShowBundlesInfo}) => {
  console.log("*********payload", payload,bundlesData);
  
  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState(false)
  const fetch = async () => {
    
    try {
      const res = await createPayPayment(payload);
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="tc_popup_bg">
    <div
      className={`custom-popup home-featured show redeem_popup ${isSubscription ? "premium_active":  "bg-primary"}`}
      id="contact_popup1"
    >
      <div className={`popup-head  ${isSubscription ? "premium_active":  "bg-primary"}`}>
        <h4>Confirm Payment</h4>
        <button className="close-popup" onClick={() =>{ setShowPayuForm(false);
          if(setShowBundlesInfo) setShowBundlesInfo(false)
        }}>
        <img src={isSubscription ? PremiumClose : Close} alt="" />
        </button>
      </div>
 
      <div className={`popup-body text-center mb-3 ${isSubscription ? "premium_active":  ""}`}>
        
        <form action={process.env.REACT_APP_PAYU_BASE_URL} method="post">
          <input type="hidden" name="key" value={payload?.key} />
          <input type="hidden" name="txnid" value={payload?.txnid} />
          <input
            type="hidden"
            name="productinfo"
            value={payload?.productInfo}
          />
          <input type="hidden" name="amount" value={payload?.amount} />
          <input type="hidden" name="email" value={payload?.email} />
          {/* <input
                    type="hidden"
                    name="firstname"
                    value={payload?.firstname}
                  /> */}

          <input type="hidden" name="udf1" value={payload?.productId} />
          <input type="hidden" name="udf2" value={payload?.userId} />
          <input type="hidden" name="udf3" value={payload?.platform} />
          <input type="hidden" name="surl" value={payload?.surl} />
          <input type="hidden" name="furl" value={payload?.furl} />
          <input type="hidden" name="phone" value={payload?.phone} />
          <input type="hidden" name="hash" value={payload?.hash} />
          {/* <input type="submit" value="submit" /> */}
          {isSubscription ? (
             <div className="d-flex flex-column align-items-center"> 
             <div className="packs_first mb-2" style={{backgroundColor:'transparent'}}>
                                <img src={PremiumLogo} alt="coin"/>
                                <p
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {payload?.description}
                                  {/* 200 */}{" "}
                                  Subscription Purchase
                                </p>
                                <p></p>
                              </div>
            <div className="ps_button">
              <button type="submit" onClick={fetch}>
                {t("premium.buy_for_amount", {
                  symbol: "₹",
                  amount: payload?.amount,
                })}
              </button>
            </div>
            </div>
          ) :
          isBundle ?
          <>
           <div className="bundles_first">
                  <img
                    src={url.imageUrl + bundlesData?.avatar?.img?.default}
                    style={{ height: "250px" }}
                    alt="avatar"
                  />
                  <p className="text-center my-2 bundle-title-box">
                    <p>
                      <b className="bundle-title-p">{bundlesData?.avatar?.name}</b>{" "}
                    </p>
                    <b>
                      <img
                        src={Coin}
                        className="coin_img mx-1"
                        style={{
                          width: "20px",
                        }}
                        alt="coin"
                      />{" "}
                      {bundlesData?.coins} {t('coin_store.coin')} + {bundlesData?.avatar?.name}
                    </b>
                  </p>
                </div>
                <button
                type="submit"
                  className="bundles_second_pop pay-btn"
                  style={{ textAlign: "center" }}
                  onClick={fetch}
                >
                  {t('coin_store.buy')} {bundlesData?.currency?.symbol} {bundlesData?.amount}
                </button>
          </>
:
          (
            <div className="d-flex flex-column align-items-center"> 
             <div className="packs_first mb-2">
                                <img src={PurchasePackIcon} alt="coin"/>
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {payload?.description}
                                  {/* 200 */}
                                </p>
                                <p>{t('coin_store.coins')}</p>
                              </div>

            <button
              type="submit"
              onClick={fetch}
              className="bundles_second_pop pay-btn text-center "
              style={{ top: 0}}
            >
              Buy ₹ {payload?.amount}
            </button>
            </div>
          )}
        </form>
        {/* <p>Please verify the payment amount displayed before proceeding further</p> */}
      </div>
    </div>
    </div>
  );
};

export default PayUForm;
