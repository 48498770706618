import instance from "./config/axios";
import instance_gj from "./config/axios-gamerji";
import routes from "./constants/routes";
import url, { SAAS_API_URL, base_url, eassUrl } from "./constants/url";

export const signIn = (data) => {
  return instance.post(`${url.authUrl}${routes.signin}`, data).then((res) => {
    return res;
  });
};

export const ValidatemailOTP = (data) => {
  return instance
    .post(`${url.authUrl}${routes.sendemailotp}`, data)
    .then((res) => {
      return res;
    });
};

export const emailOtp = (data) => {
  return instance.post(`${url.authUrl}${routes.emailotp}`, data).then((res) => {
    return res;
    // console.log(res)
  });
};

export const signInInternational = (data) => {
  return instance
    .post(`${url.authUrl}${routes.signinInternational}`, data)
    .then((res) => {
      return res;
    });
};
export const signUpEmail = (data) => {
  return instance
    .post(`${url.authUrl}${routes.signupEmail}`, data)
    .then((res) => {
      return res;
      // console.log(res)
    });
};

export const signInEmail = (data) => {
  return instance
    .post(`${url.authUrl}${routes.signinEmail}`, data)
    .then((res) => {
      return res;
    });
};

// World of eSports Starts
export const woeLiveStreamingsList = (data) => {
  return instance
    .post(`${url.webAppApiUrl}${routes.woeLiveStreamingsList}`, data)
    .then((res) => {
      return res;
    });
};

export const woeUsersList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.woeUsersList}`, data)
    .then((res) => {
      //console.log("woeUsersList: response test sk", res);
      return res;
    })
    .catch((err) => {
      //console.log("woeUsersList: err", err);
      //return res;
    });
};

export const woeBlogsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.woeBlogsList}`, data)
    .then((res) => {
      return res;
    });
};
// World of eSports Ends

// All Screens ID Starts
export const apkScreensList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.apkScreensList}`, data)
    .then((res) => {
      return res;
    });
};
// All Screens ID Ends

// Sponsors Ads Banner Ads Starts
export const sponsorAdsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.sponsorAdsList}`, data)
    .then((res) => {
      return res;
    });
};

export const sponsorAdLog = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.sponsorAdLog}`, data)
    .then((res) => {
      return res;
    });
};

export const bannerAdLog = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.bannerAdLog}`, data)
    .then((res) => {
      return res;
    });
};
// Sponsors Ads Banner Ads Ends

// Apply Promo Code Starts
export const applyPromoCode = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.applyPromoCode}`, data)
    .then((res) => {
      return res;
    });
};
// Apply Promo Code Ends

// How To Play Starts
export const howToPlay = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.howToPlay}`, data)
    .then((res) => {
      return res;
    });
};
// How To Play Ends

// DOBNState Starts
export const updateDOBNState = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.updateDOBNState}`, data)
    .then((res) => {
      return res;
    });
};

export const sendOTP = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.sendOTP}`, data)
    .then((res) => {
      return res;
    });
};

export const validateOTP = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.validateOTP}`, data)
    .then((res) => {
      return res;
    });
};
// DOBNState Ends

// Contests Starts
export const checkDateTime = (id) => {
  return instance
    .post(`${url.saasApiUrl}${routes.checkDateTime}/${id}`)
    .then((res) => {
      return res;
    });
};
// Contests Ends

// Customer Care Starts
export const userContestList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.userContestList}`, data)
    .then((res) => {
      return res;
    });
};
// Customer Care Ends

// Free Games Starts
export const html5SettingsList = (data) => {
  return instance
    .post(`${url.webAppApiUrl}${routes.html5SettingsList}`, data)
    .then((res) => {
      return res;
    });
};

export const html5CategoriesList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.html5CategoriesList}`, data)
    .then((res) => {
      return res;
    });
};

export const html5GamesList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.html5GamesList}`, data)
    .then((res) => {
      return res;
    });
};

export const html5GamesGet = (id) => {
  return instance
    .post(`${url.apiUrl}${routes.html5GamesGet}/${id}`)
    .then((res) => {
      return res;
    });
};

export const html5GamesUpdateView = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.html5GamesUpdateView}`, data)
    .then((res) => {
      return res;
    });
};
// Free Games Ends

// My Recent Transactions Starts
export const transactionsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.transactionsList}`, data)
    .then((res) => {
      return res;
    });
};

export const transactionsSendInvoiceEmail = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.transactionsSendInvoiceEmail}/${data}`)
    .then((res) => {
      return res;
    });
};

export const transactionsExport = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.transactionsExport}`, data)
    .then((res) => {
      return res;
    });
};
// My Recent Transactions Ends

// Payment Gateway Starts
export const paymentOptions = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.paymentOptions}`, data)
    .then((res) => {
      return res;
    });
};

export const cfBankList = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.cfBankList}`, data)
    .then((res) => {
      return res;
    });
};
// Payment Gateway Ends

// Verify Mobile & Email Starts
export const checkEmail = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.checkEmail}`, data)
    .then((res) => {
      return res;
    });
};
// Verify Mobile & Email Ends

// My Videos Starts
export const userVideoRequestsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.userVideoRequestsList}`, data)
    .then((res) => {
      return res;
    });
};

export const userVideoRequestsVideoList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.userVideoRequestsVideoList}`, data)
    .then((res) => {
      return res;
    });
};

export const userVideoRequestsCheckChannel = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.userVideoRequestsCheckChannel}`, data)
    .then((res) => {
      return res;
    });
};

export const userVideoRequestsCreate = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.userVideoRequestsCreate}`, data)
    .then((res) => {
      return res;
    });
};
// My Videos Ends

export const signUpInternational = (data) => {
  return instance
    .post(`${url.authUrl}${routes.signupInternational}`, data)
    .then((res) => {
      return res;
    });
};

export const checkAppUser = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.checkAppUser}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllStateList = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.stateList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllFreeAvatar = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.avatarFree}`, data)
    .then((res) => {
      return res;
    });
};

export const getsignUpCode = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.signupCode}`, data)
    .then((res) => {
      return res;
    });
};

export const countryList = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.countries}`, data)
    .then((res) => {
      return res;
    });
};

export const geolocation = () => {
  return fetch("https://geolocation-db.com/json/")
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export const PrivacyData = (data) => {
  return instance.post(`${url.publicUrl}${routes.privacy}`).then((res) => {
    return res;
  });
};

export const TermsData = (data) => {
  return instance.post(`${url.publicUrl}${routes.terms}`).then((res) => {
    return res;
  });
};

export const allGames = (data) => {
  return instance.post(`${url.gamesWebUrl}${routes.list}`, data).then((res) => {
    return res;
  });
};

export const gameTypes = (data) => {
  // console.log(`${url.gamesTypesUrl}${routes.list}`)
  return instance
    .post(`${url.gamesTypesUrl}${routes.list}`, data)
    .then((res) => {
      return res;
    });
};

export const getTournamentList = (data) => {
  // console.log(`getTournamentList: ${url.eventUrl}${routes.list}`)
  return instance
    .post(`${url.webAppUrl}${routes.tournamentList}`, data)
    .then((res) => {
      return res;
    });
};

export const homebanner = (data) => {
  return instance.post(`${url.bannerUrl}${routes.list}`, data).then((res) => {
    return res;
  });
};

export const getevents = (body, id) => {
  //eventListUrl
  return instance
    .post(`${url.webAppUrl}${routes.tournamentInfo}${id}`, body)
    .then((res) => {
      return res;
    });
};

export const getWalletLimit = (data) => {
  return instance
    .post(`${url.webAppApiUrl}${routes.walletusage}`, data)
    .then((res) => {
      return res;
    });
};

export const getUserProfile = () => {
  // console.log(
  //   "path>>>getUserProfile>>>>:",
  //   `${url.accountUrl}${routes.profile}`
  // );
  // return instance.post(`${url.apiUrl}${routes.woeUsersList}/${new Date().getTime()}`, data)
  return instance.post(`${url.accountUrl}${routes.profile}`).then((res) => {
    return res;
  });
};
// export const getUserProfileLite = () => {
//   console.log(
//     "path>>>profileLite>>>>:",
//     `${url.accountUrl}${routes.profileLite}`
//   );
//   // return instance.post(`${url.apiUrl}${routes.woeUsersList}/${new Date().getTime()}`, data)
//   return instance.post(`${url.accountUrl}${routes.profileLite}`).then((res) => {
//     return res;
//   });
// };
export const getUserFavouritesGames = () => {
  // console.log(
  //   "path>>>profileFavGames>>>>:",
  //   `${url.accountUrl}${routes.profileFavGames}`
  // );
  // return instance.post(`${url.apiUrl}${routes.woeUsersList}/${new Date().getTime()}`, data)
  return instance.post(`${url.accountUrl}${routes.profileFavGames}`).then((res) => {
    return res;
  });
};
export const getUserBasicsDetails = () => {
  // console.log(
  //   "path>>>userBasicDetails>>>>:",
  //   `${url.accountUrl}${routes.profileBasicDetails}`
  // );
  // return instance.post(`${url.apiUrl}${routes.woeUsersList}/${new Date().getTime()}`, data)
  return instance.post(`${url.accountUrl}${routes.profileBasicDetails}`).then((res) => {
    return res;
  });
};

export const getOtherUserProfile = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.profile}`, data)
    .then((res) => {
      return res;
    });
};
export const removeFolowUser = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.followUser}`, data)
    .then((res) => {
      return res;
    });
};
export const onCreateContest = (data) => {
  // console.log(
  //   "onCREATE CONTEST_API REQ:",
  //   `${url.saasApiUrl}${routes.createContest}`,
  //   data
  // );
  return instance
    .post(`${url.saasApiUrl}${routes.createContest}`, data)
    .then((res) => {
      return res;
    });
};

export const onCreateTournament = (data) => {
  //${url.apiUrl}${routes.createTournament}
  return instance
    .post(`${url.saasApiUrl}${routes.tournamentJoin}`, data)
    .then((res) => {
      return res;
    });
};

export const onCreateScrim = (data) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.joinScrim}`, data)
    .then((res) => {
      return res;
    });
};

// export const onCreateTournament = (data) => {
//   console.log("onCREATE TOURNAMENT_API REQ:", `${url.apiUrl}${routes.createTournament}`, data)
//   return instance.post(`${url.apiUrl}${routes.createTournament}`, data)
//     .then((res) => {
//       return res;
//     });
// };

export const getContestList = (data) => {
  return instance
    .post(`${url.webAppUrl}${routes.contestlist}`, data)
    .then((res) => {
      return res;
    });
};
export const getContestDetails = (id) => {
  return instance.post(`${url.webAppUrl}${routes.contestGet}${id}`).then((res) => {
    return res;
  });
};
export const getOldContestDetails = (id) => {
  return instance.post(`${url.contestOldUrl}${routes.get}${id}`).then((res) => {
    return res;
  });
};
export const getContestEventByCode = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.contesteventbycode}`, data)
    .then((res) => {
      return res;
    });
};

export const getContestMyList = (data) => {
  return instance
    .post(`${url.contestUrl}${routes.mylist}`, data)
    .then((res) => {
      return res;
    });
};

export const getUpdateProfile = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.updateprofile}`, data)
    .then((res) => {
      return res;
      // console.log(res)
    });
};

export const getDailyRewards = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.dailyreward}`, data)
    .then((res) => {
      return res;
    });
};

export const getListofDailyRewards = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.listdailyreward}`, data)
    .then((res) => {
      return res;
    });
};

export const getCollectDailyRewards = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.collectdailyreward}`, data)
    .then((res) => {
      return res;
    });
};

export const getCoinStoreList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.coinStoreList}`, data)
    .then((res) => {
      return res;
    });
};
export const getCoinStoreDetails = (id) => {
  return instance
    .post(`${url.apiUrl}${routes.coinStoreGet}${id}`)
    .then((res) => {
      return res;
    });
};
export const requestCreateCoinStoreTransactions = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.createRazTransaction}`, data)
    .then((res) => {
      return res;
    });
};
export const requestCreateCFCoinStoreTransactions = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.createCashfreeTransaction}`, data)
    .then((res) => {
      return res;
    });
};
export const requestLevelList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.levelsList}`, data)
    .then((res) => {
      return res;
    });
};
export const requestGameStats = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.gameStatistics}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllAvatarList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.avatarList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllRewardCategoryList = (data) => {
  return instance
    .post(`${url.webAppApiUrl}${routes.rewardsList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllRewardProductMyList = (data) => {
  return instance
    .post(`${url.webAppApiUrl}${routes.rewardsMyList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllLeaderboardsList = (data) => {
  return instance
    .post(`${url.webAppUrl}${routes.tournamentLeaderBoard}`, data)
    .then((res) => {
      return res;
    });
};

export const howToJoinGame = (data) => {
  return instance.post(`${url.apiUrl}${routes.howToJoin}`, data).then((res) => {
    return res;
  });
};

export const getMyContestList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.myContestList}`, data)
    .then((res) => {
      return res;
    });
};
export const getMyOldContestList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.myOldContestList}`, data)
    .then((res) => {
      return res;
    });
};

export const getMyEventsList = (data) => {
  //url.apiUrl
  return instance
    .post(`${url.saasApiUrl}${routes.myEventsList}`, data)
    .then((res) => {
      return res;
    });
};

export const getLeaderBoardList = (type, data) => {
  return instance
    .post(`${url.accountUrl}${routes.leaderboard}${type}`, data)
    .then((res) => {
      return res;
    });
};

export const getSearchUserList = (data) => {
  // console.log(
  //   "path>>>> getSearchUserList>>>:",
  //   `${url.apiUrl}${routes.searchUserList}`
  // );
  return instance_gj
    .post(`${url.apiUrl}${routes.searchUserList}`, data)

    .then((res) => {
      return res;
    });
};
export const getCollegeList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.collegeList}`, data)
    .then((res) => {
      return res;
    });
};

export const getApplyReward = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.applyReward}`, data)
    .then((res) => {
      return res;
    });
};
export const getClientSecret = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.createPaymentIntent}`, data)
    .then((res) => {
      return res;
    });
};
export const createStripeTransactionWeb = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.createStripeTransactionWeb}`, data)
    .then((res) => {
      return res;
    });
};
export const createStripePaymentIntentWeb = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.createStripePaymentIntentWeb}`, data)
    .then((res) => {
      return res;
    });
};
export const getBuyAvatar = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.buyAvatar}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllPoints = (data) => {
  return instance.post(`${url.apiUrl}${routes.allPoints}`, data).then((res) => {
    return res;
  });
};

export const getAllPointsCategory = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.allPointsCategories}`, data)
    .then((res) => {
      return res;
    });
};

export const getContestRate = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.getContestRate}`, data)
    .then((res) => {
      return res;
    });
};

export const contestRate = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.contestRate}`, data)
    .then((res) => {
      return res;
    });
};

export const getScrimRate = (data) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.rateScrim}`, data)
    .then((res) => {
      return res;
    });
};

export const getApplyEventCode = (data) => {
  //${url.accountUrl}
  return instance
    .post(`${url.webAppUrl}${routes.applyEventCode}`, data)
    .then((res) => {
      return res;
    });
};

export const getTicketLists = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.ticketList}`, data)
    .then((res) => {
      return res;
    });
};

export const getGamesDetails = (id) => {
  return instance.get(`${url.gamesUrl}${routes.get}${id}`).then((res) => {
    return res;
  });
};

export const getGamesDetails1 = (id) => {
  return instance.post(`${url.apiUrl}games/${routes.get}${id}`).then((res) => {
    return res;
  });
};

export const getCategoryLists = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.categoryList}`, data)
    .then((res) => {
      return res;
    });
};

export const getRankSummeryByGame = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.rankSummeryByGame}`, data)
    .then((res) => {
      return res;
    });
};

export const getSearchGames = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.searchGames}`, data)
    .then((res) => {
      return res;
    });
};

export const getContestByGame = (data) => {
  //console.log(`getContestByGame: ${url.accountUrl}${routes.contestByGame}`);
  return instance
    .post(`${url.accountUrl}${routes.contestByGame}`, data)
    .then((res) => {
      return res;
    });
};
export const getFollowingList = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.followings}`, data)
    .then((res) => {
      return res;
    });
};
export const getFollowerList = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.followers}`, data)
    .then((res) => {
      return res;
    });
};

export const getUploadSS = (data) => {
  return instance.post(`${url.apiUrl}${routes.uploadSS}`, data).then((res) => {
    return res;
  });
};

export const getCreateComplaint = (data) => {
  return instance_gj
    .post(`${url.apiUrl}${routes.createTicket}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllTicketChat = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.getTicketChat}${data}`, {})
    .then((res) => {
      return res;
    });
};

export const updateAllTicketChat = (id, data) => {
  return instance
    .post(`${url.apiUrl}${routes.updateTicketChat}${id}`, data)
    .then((res) => {
      return res;
    });
};
export const checkUserDetails = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.checkUsers} `, data)
    .then((res) => {
      return res;
    });
};

export const getGenrateSignature = (data) => {
  return instance
    .post("https://www.gamerji.com/signature.php", data)
    .then((res) => {
      return res;
    });
};

export const onSignUp = (data) => {
  return instance.post(`${url.authUrl}${routes.signup}`, data).then((res) => {
    return res;
  });
};

export const getCampaign = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.campaignsCompaniesDetails}`, data)
    .then((res) => {
      return res;
    });
};
export const getGoogleRecaptcha = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.verifyRecaptcha}`, data)
    .then((res) => {
      return res;
    });
};
export const requestCreateCFCoinStoreTransactionWeb = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.createCashfreeTransactionWeb}`, data)
    .then((res) => {
      return res;
    });
};
export const requestCFLink = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.requestCashfreePaymentlink}`, data)
    .then((res) => {
      return res;
    });
};

export const getTransactionDetails = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.getTransactionDetails}`, data)
    .then((res) => {
      return res;
    });
};
export const requestLinkBankAccount = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.requestLinkBankAccount}`, data)
    .then((res) => {
      return res;
    });
};
export const requestLinkUpiAccount = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.requestLinkUpiAccount}`, data)
    .then((res) => {
      return res;
    });
};

export const requestVerifyVPA = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.requestVerifyVPA}`, data)
    .then((res) => {
      return res;
    });
};
export const requestWithdrawal = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.requestWithdrawal}`, data)
    .then((res) => {
      return res;
    });
};

export const requestGoogleLogin = (data) => {
  return instance
    .post(`${url.authUrl}${routes.googleLogin}`, data)
    .then((res) => {
      return res;
    });
};

export const getUserInGameName = (data) => {
  return instance
    .post(`${url.webAppUrl}${routes.getInGameName}`, data)
    .then((res) => {
      return res;
    });
};
export const addUserInGameName = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.addInGameName}`, data)
    .then((res) => {
      return res;
    });
};
export const getContestPlayerList = (id, data) => {
  return instance
    .post(`${url.webAppUrl}${routes.contestPlayerListNew}${id}`, data)
    .then((res) => {
      return res;
    });
};

export const getApplyContestCode = (code, data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.applyContestCode}${code}`, data)
    .then((res) => {
      return res;
    });
};
export const returnPaymentData = (body) => {
  return instance
    .post(`${url.publicUrl}${routes.returnPayment}`, body)
    .then((res) => {
      return res;
    });
};

export const getHowToCreateScrim = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.howToCreateScrim}`, body)
    .then((res) => {
      return res;
    });
};
export const createScrimFormSubmission = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.createScrim}`, body)
    .then((res) => {
      return res;
    });
};
export const getGameTypeById = (body) => {
  return instance
    .post(`${url.webAppUrl}${routes.getGameTypeById}`, body)
    .then((res) => {
      //console.log("returnned", res);
      return res;
    })
    .catch((err) => console.log("error"));
};

export const getGameRules = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.gameRules}`, body)
    .then((res) => {
      return res;
    });
};

export const viewScrimById = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.manageScrim}`, body)
    .then((res) => {
      return res;
    });
};
export const updateScrimById = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.updateScrim}`, body)
    .then((res) => {
      return res;
    });
};
export const updateRoomIdandPassword = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.roomIdAndPassword}`, body)
    .then((res) => {
      return res;
    });
};

export const scrimLeaderBoard = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.scrimLeaderBoard}`, body)
    .then((res) => {
      return res;
    });
};

// export const scrimList = (body) => {
//   return instance
//     .post(`${SAAS_API_URL}${url.v2Url}${routes.scrimList}`, body)
//     .then((res) => {
//       return res;
//     });
// };
//getScrimById

export const getScrimById = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.getScrimById}`, body)
    .then((res) => {
      return res;
    });
};
//scrim/updateWinners
export const updateWinners = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.updateWinners}`, body)
    .then((res) => {
      return res;
    });
};
//statusUpdate
export const statusUpdate = (body, id) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.statusUpdate}${id}`, body)
    .then((res) => {
      return res;
    });
};
//ssUpload
export const ssUpload = (body, id) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.ssUpload}${id}`, body)
    .then((res) => {
      return res;
    });
};
// getScrimByID
export const getScrimByUserId = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.getScrimByUserId}`, body)
    .then((res) => {
      return res;
    });
};
//getStatusScrim
export const getStatusScrim = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.getStatusScrim}`, body)
    .then((res) => {
      return res;
    });
};
//getUserDetail

export const getUserDetail = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.getUserDetail}`, body)
    .then((res) => {
      return res;
    });
};
//createPhonepeTransaction

export const createPhonepeTransaction = (body) => {
  return instance
    .post(`${base_url}${routes.createPhonepeTransaction}`, body)
    .then((res) => {
      return res;
    });
};
// https://gamerji-api-staging.gamerji.cloud/account/upadte-phonepay-transaction/
export const phonepeStatus = (body) => {
  return instance
    .post(`${base_url}api/account/upadte-phonepay-transaction`, body)
    .then((res) => {
      return res;
    });
};
export const getSettings = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.getSettings}`, body)
    .then((res) => {
      return res;
    });
};
export const createXsollaTransaction = (body) => {
  return instance
    .post(`${base_url}api/account/create-xsolla-transaction`, body)
    .then((res) => {
      return res;
    });
};

export const createXsollaSubscriptionTransaction = (body) => {
  return instance
    .post(`${base_url}api/account/create-xsolla-subscription-transaction`, body)
    .then((res) => {
      return res;
    });
};

export const createXsollaUnSubscriptionTransaction = (body) => {
  return instance
    .post(`${base_url}api/account/unsubscription`, body)
    .then((res) => {
      return res;
    });
};

export const xsollaSubscriptionStatus = (body) => {
  return instance
    .post(`${base_url}api/account/update-xsolla-subscription-transaction`, body)
    .then((res) => {
      return res;
    });
};

export const xsollaStatus = (body) => {
  return instance
    .post(`${base_url}api/account/update-xsolla-transaction`, body)
    .then((res) => {
      return res;
    });
};
export const getAds = (body) => {
  return instance
    .post(`${url.webAppUrl}${routes.getAds}`, body)
    .then((res) => {
      return res;
    });
};
export const xsollaList = (body) => {
  return instance
    .post(`${url.apiUrl}${routes.xsollaList}`, body)
    .then((res) => {
      return res;
    });
};
export const updateCoin = (body) => {
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.updateCoin}`, body)
    .then((res) => {
      return res;
    });
};
export const getSubscription = (id) => {
  //http://localhost:9155/
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.getSubscription}/${id}`)
    .then((res) => {
      return res;
    });
};
export const createSubscriptionTransaction = (body) => {
  //http://localhost:9155/
  return instance
    .post(`${url.accountUrl}${routes.createSubscriptionTransaction}`, body)
    .then((res) => {
      return res;
    });
};
export const updateSubscriptionTransaction = (body) => {
  //http://localhost:9155/
  return instance
    .post(`${url.accountUrl}${routes.updateSubscriptionTransaction}`, body)
    .then((res) => {
      return res;
    });
};
export const getSubscriptionTypeList = (data) => {
  //http://localhost:9155/
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.getSubscriptionTypeList}`, data)
    .then((res) => {
      return res;
    });
};
export const getSubscriptionUserDetails = (id) => {
  //http://localhost:9155/
  return instance
    .post(
      `${SAAS_API_URL}${url.v2Url}${routes.getSubscriptionUserDetails}${id}`
    )
    .then((res) => {
      return res;
    });
};
export const isUserPremium = (id) => {
  //http://localhost:9155/
  return instance
    .post(`${url.webAppUrl}${routes.isUserPremium}${id}`)
    .then((res) => {
      return res;
    });
};
export const getTimeSlots = () => {
  //http://localhost:9155/
  return instance
    .post(`${SAAS_API_URL}${url.v2Url}${routes.getTimeSlots}`)
    .then((res) => {
      return res;
    });
};

export const createAirPayTransaction = (body) => {
  return instance
    .post(`${base_url}api/account/create-airpay-transaction`, body)
    .then((res) => {
      return res;
    });
};
export const verifyAirPayTransaction = (body) => {
  return instance
    .post(`${base_url}api/account/verify-airpay-transaction`, body)
    .then((res) => {
      return res;
    });
};
export const getLanguage = (data) => {
  return instance
    .post(`${SAAS_API_URL}api/language/get`, data)
    .then((res) => {
      return res;
    });
};

export const getExternalGamePBE = (data) => {
  return instance
    .get(`${SAAS_API_URL}api/external-platform/game/get/PBE`, data)
    .then((res) => {
      return res;
    });
};

export const getExternalGamePBL = (data) => {
  return instance
    .get(`${SAAS_API_URL}api/external-platform/game/get/PBL`, data)
    .then((res) => {
      return res;
    });
};

export const getFeaturedTournamentList = (data) => {
  // console.log(`getTournamentList: ${url.eventUrl}${routes.list}`)
  return instance
    .post(`${url.webAppUrl}${routes.featuredTournamentList}`, data)
    .then((res) => {
      return res;
    });
};

export const userRenewDetails = (id) => {
  //http://localhost:9155/
  return instance
    .post(`${url.webAppUrl}${routes.renewDetails}${id}`)
    .then((res) => {
      return res;
    });
};

export const getUserProfileLite = () => {
  return instance.post(`${url.webAppApiUrl}${routes.walletDetails}`).then((res) => {
    return res;
  });
};

export const getPrizePool = (data) => {
  return instance
    .post(`${url.webAppUrl}${routes.getPrizePool}${data}`)
    .then((res) => {
      return res;
    });
};

export const getRules = (data) => {
  return instance
    .post(`${url.webAppUrl}${routes.getRules}${data}`)
    .then((res) => {
      return res;
    });
};

export const tournamentRoundsById = (data) => {
  return instance
    .post(`${url.webAppUrl}${routes.tournamentRoundsById}${data}`)
    .then((res) => {
      return res;
    });
};

export const tournamentMatchesById = (id, data) => {
  return instance
    .post(`${url.webAppUrl}${routes.tournamentMatchesById}${id}`, data)
    .then((res) => {
      return res;
    });
};

export const scrimList = (body) => {
  return instance
    .post(`${url.webAppUrl}${routes.scrimList}`, body)
    .then((res) => {
      return res;
    });
};
export const getContestRules = (id) => {
  return instance.post(`${url.webAppUrl}${routes.contestRules}${id}`).then((res) => {
    return res;
  });
};
export const getJoinTournamentRounds = (body) => {
  return instance.post(`${url.webAppUrl}${routes.tournamentRounds}`, body).then((res) => {
    return res;
  });
};

export const getProfile = () => {
  return instance.post(`${url.webAppApiUrl}${routes.accountProfile}`).then((res) => {
    return res;
  });
};
export const getEditProfile = () => {
  return instance.post(`${url.webAppApiUrl}${routes.accountEditProfile}`).then((res) => {
    return res;
  });
};
export const updateProfile = (body) => {
  return instance.post(`${url.webAppApiUrl}${routes.accountUpdateProfile}`, body).then((res) => {
    return res;
  });
};
export const getReferralData = () => {
  return instance.post(`${url.webAppApiUrl}${routes.accountReferral}`).then((res) => {
    return res;
  });
};
export const getFavouriteGames = () => {
  return instance.post(`${url.webAppUrl}${routes.gameFavourite}`).then((res) => {
    return res;
  });
};
export const MyTournamentList = (body) => {
  return instance.post(`${url.webAppUrl}${routes.myTournamentList}`, body).then((res) => {
    return res;
  });
};
export const viewScrimRulesById = (body) => {
  return instance
    .post(`${url.webAppUrl}${routes.viewScrimRules}`, body)
    .then((res) => {
      return res;
    });
};
export const getUserDetails = (id, body) => {
  return instance
    .post(`${url.webAppUrl}${routes.contestUserDetails}${id}`, body)
    .then((res) => {
      return res;
    });
};
export const gameTypes1 = (data) => {
  // console.log(`${url.gamesTypesUrl}${routes.list}`)
  return instance
    .post(`${url.webAppUrl}${routes.gameTypeList}`, data)
    .then((res) => {
      return res;
    });
};
export const payUHash = (data) => {
  // console.log(`${url.gamesTypesUrl}${routes.list}`)
  return instance
    .post(`${url.webAppApiUrl}${routes.payUHash}`, data)
    .then((res) => {
      return res;
    });
};
export const createPayPayment = (data) => {
  // console.log(`${url.gamesTypesUrl}${routes.list}`)
  return instance
    .post(`${url.webAppApiUrl}${routes.createPayPayment}`, data)
    .then((res) => {
      return res;
    });
};