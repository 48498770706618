export const logout = () => {
    localStorage.removeItem('gamerjiLogindetails');
    localStorage.removeItem("profileLite");
    // localStorage.removeItem(constants.profileBasics);
    // localStorage.removeItem(constants.profileFavGames);
    localStorage.removeItem('gamerjiToken');
    localStorage.removeItem('activeGame');
    localStorage.removeItem('currentPage');
    localStorage.removeItem('joinTounament');
    localStorage.removeItem('tournamentId');
    localStorage.removeItem('activeGameId');
    localStorage.removeItem('tournamentImgs');
    localStorage.removeItem('currentCoin');
    localStorage.removeItem('userCountryObj');
    localStorage.removeItem('confirmationResult');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('currentOTPtype');
    // localStorage.removeItem('languageData');
    localStorage.removeItem('loginData');
    localStorage.removeItem('userInGameNames');
    localStorage.removeItem('activeGameType');
    localStorage.removeItem('scrimData');
    localStorage.removeItem('players_data');
    localStorage.removeItem('premiumUser');
    localStorage.removeItem('i18nextLng');
    localStorage.removeItem('isDefault');



    window.history.replaceState(null, '', '/');
    window.location.href = '/';
  }