import React, { Fragment } from "react";
import Logo from "../assets/images/Gamerji_Text_logo_With_Tagline_White.png";
import diamond from "../assets/images/BLUE_Diamond.png";
import coin from "../assets/images/coin.png";
import Wallet from "../assets/images/wallet.svg";
import { Link } from "react-router-dom";
import { routehelp } from "../config/routehelp";
import Tooltip from "@mui/material/Tooltip";
import Cookies from 'js-cookie'

function Header(props) {
  const navigationClick = () => {
    props.navMenuClick();
  };
  const redirectToCoinStore = () => {
    window.location.href = routehelp.coinstore;
  };

  const abbreviate_number = (num, fixed) => {
    //console.log(num);
    if (num === null) {
      return null;
    } // terminate early
    if (num === 0) {
      return "0";
    } // terminate early
    fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
    var b = num.toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c =
        k < 1
          ? num.toFixed(0 + fixed)
          : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ["", "K", "M", "B", "T"][k]; // append power
    return e;
  };

  const currentLang = Cookies.get('i18next') || 'EN'

  const getTotalDiamond = () => {
    let wallet = localStorage.getItem("currentDiamond");
    return Math.round(wallet);
  };
  const getTotalCoin = () => {
    let wallet = localStorage.getItem("currentCoin");
    return Math.round(wallet);
  };
  return (
    <Fragment>
      <div className="header">
        {/* <!-- Mobile Menu Start --> */}
        {/* <div className="">
							<div id="burgerBtn" onClick={navigationClick}></div>
							<ul id="nav">
									<li><Link to="">All Games</Link></li>
									 <li><Link to="">All Games</Link></li>
									<li><Link to="">All Games</Link></li>
								<li>	<Link to="">All Games</Link></li>
									 <li><Link to="">All Games</Link></li>
							</ul>
						</div> */}
        <div className="logo" style={currentLang.toLowerCase() == "ar" ? { marginBottom: 30, marginRight: "-2.2rem", } : { marginBottom: 30, marginLeft: 10, }}>
          <Link to="">
            <img src={Logo} alt="" style={{ width: "130px" }} />
          </Link>
        </div>

        <div className="header-responsive header-icons">
          <Link
            className="header-wallet"
            style={{
              // padding: 10,
              display: "flex",
              borderRadius: "50px",
              border: "1px solid #fff",
              flexGrow: "1",
              textAlign: "center",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",

            }}
            to={routehelp.coinstore}
          >
            <img src={coin} alt="" style={{ width: '28px', marginRight: '5px' }} />
            {/* <Tooltip title={localStorage.getItem("currentCoin")}> */}
            <span
              className="header-wallet-coins"
            // style={{
            //   marginLeft: 3,
            //   color: "#fff",

            //   alignSelf: "center",
            //   display: "inline-block",
            //   maxWidth: "100%",
            // }}
            >
              {"  "}
              {/* {localStorage.getItem("currentCoin")}{" "} */}
              {/* {getTotalCoin()}{" "} */}
              {props?.walletDetails?.wallet?.filter((curr)=> curr?.currencyData?.code == process.env.REACT_APP_CURRENCY_CODE)?.[0]?.winningAmount}

            </span>
            {/* </Tooltip> */}
          </Link>
          {currentLang.toLowerCase() == "ar" ?
            <Link
              className="header-wallet"
              style={{
                // padding: 10,
                marginRight: 10,
                display: "flex",
                borderRadius: "50px",
                border: "1px solid #fff",
                flexGrow: "1",
                textAlign: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              to={routehelp.coinstore}
            >
              <img src={diamond} alt="" style={{ width: '28px', marginRight: '5px' }} />
              {/* <Tooltip title={localStorage.getItem("currentDiamond")}> */}
              <a
                style={{
                  marginLeft: 5,
                  color: "#fff",
                  alignSelf: "center",
                  display: "inline-block",
                  maxWidth: "100%",
                }}
              >
                {"  "}
                {/* {localStorage.getItem("currentDiamond")}{" "} */}
                {/* {getTotalDiamond()}{" "} */}
                {
                Math.round(props?.walletDetails?.wallet?.filter((curr)=> curr?.currencyData?.code == process.env.REACT_APP_CURRENCY_CODE_OUT)?.[0]?.winningAmount)
                }

              </a>
              {/* </Tooltip> */}
            </Link>
            :
            <Link
              className="header-wallet"
              style={{
                // padding: 10,
                marginLeft: 10,
                display: "flex",
                borderRadius: "50px",
                border: "1px solid #fff",
                flexGrow: "1",
                textAlign: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              to={routehelp.coinstore}
            >
              <img src={diamond} alt="" style={{ width: '28px', marginRight: '5px' }} />
              {/* <Tooltip title={localStorage.getItem("currentDiamond")}> */}
              <a
                style={{
                  marginLeft: 5,
                  color: "#fff",
                  alignSelf: "center",
                  display: "inline-block",
                  maxWidth: "100%",
                }}
              >
                {"  "}
                {/* {localStorage.getItem("currentDiamond")}{" "} */}
                {/* {getTotalDiamond()}{" "} */}
               { 
               Math.round(props?.walletDetails?.wallet?.filter((curr)=> curr?.currencyData?.code == process.env.REACT_APP_CURRENCY_CODE_OUT)?.[0]?.winningAmount)
               }

              </a>
              {/* </Tooltip> */}
            </Link>
          }

        </div>

        {/* <div className="wallet-btn"> */}
        {/*	<Link to="">
								<img src={Wallet} alt=""/>
								Wallet
							</Link> */}
        {/* </div> */}
      </div>
    </Fragment>
  );
}

export default Header;
