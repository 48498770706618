import React, { useEffect, useState } from "react";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import { Link } from "react-router-dom";
import validator from "validator";
import { updateProfile, getAllFreeAvatar, getAllStateList, getEditProfile } from "../apis";
import url from "../constants/url";
import dateIcon from "../assets/images/date-icon.svg";
import rightArrow from "../assets/images/right-arrow.svg";
import { getHTMLdateFormat } from "../component/common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { constants } from "../constants/constants";
import Cancel from "../assets/images/round_not_qualified.png";
import "./profile.css";
import { routehelp } from "../config/routehelp";
import Lock from "../assets/images/lock.svg";
import PremiumBlack from "../assets/images/GoldPremium.png";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router";

function ProfileEdit() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const [tagStatePopup, setTagStatePopup] = useState(false);
  const [userData, setUserData] = useState("");

  const [allAvatars, setAllAvatars] = useState([]);
  const [allStates, setAllStates] = useState();
  const [avatarId, setAvatarId] = useState("");
  const [getOptions, setOptions] = useState();
  const [selectedState, setSelectedState] = useState();
  const [premiumUser, setPremiumUser] = useState(
    JSON.parse(localStorage.getItem("premiumUser"))
  );
  const [selectedDob, setSelectedDob] = useState("");

  const { t } = useTranslation()

  const handleStateClick = (selected) => {
    if (selected.type === "state") {
      setSelectedState(selected);
      // setSelectedCountry(null);
    } else if (selected.type === "country") {
      // setSelectedCountry(selected);
      setSelectedState(null);
    }
    setTagStatePopup(false);
  };

  const onSubmit = async () => {
    if (!userData?.isMobileVerified) {
      if (userData?.phone === "" || userData?.phone == undefined) {
        toast.error(t('error_please_mobile_number'), {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (userData?.phone?.length < 10) {
        toast.error(t('error_please_enter_valid_mobile_number'), {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
    }

    if (selectedDob === "" || selectedDob == undefined) {
      toast.error(t('error_select_date_of_birth'), {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }

    if (!userData?.isEmailVerified) {

      if (userData?.email === "" || !validator.isEmail(userData?.email)) {
        toast.error(t('error_Please_enter_a_valid_email_address'), {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
    }
    if (userData?.gamerjiName === "") {
      toast.error(t('error_please_add_gamerji_name'), {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    // debugger
    let data = {
      // email: userData?.email,
      gamerjiName: userData?.gamerjiName,
      dateOfBirth: getHTMLdateFormat(selectedDob),
      avatar: avatarId,
      // phone: userData?.phone,
    };

    if(!userData?.isEmailVerified){
      data.email= userData?.email
    }

    if(!userData?.isMobileVerified) {
      data.phone= userData?.phone
    }

    if (getOptions !== undefined) {
      data.address = { state: selectedState?._id };
    } else {
      data.address = { state: selectedState?._id };
    }

    // let res = await getUpdateProfile(data);

    setLoader(true);
    let res = await updateProfile(data);

    if (res.success) {
      setLoader(false);
      // setAllData(res);
      // setUserData(res.item);
      // setSelectedDob(res?.item?.dateOfBirth);

      // localStorage.setItem(constants.profileLite, JSON.stringify({ res }));



      // localStorage.setItem(constants.profileBasics, JSON.stringify({
      //   success: true,
      //   level: res?.item?.level,
      //   subscriptionDetails: res?.item?.subscriptionDetails,
      //   country: res?.country,
      //   state: res?.state,
      //   college: res?.item?.collegeDetails
      // }));



      toast.success(t('success_editprofile_updated_successfully'), {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigate("/profile")
      }, 3000);
    } else {
      toast.error(res.data?.errors?.[0].msg, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true)

    let profileLiteResponse = await getEditProfile();
    if (profileLiteResponse) {
      setUserData(profileLiteResponse.item);
      setOptions(profileLiteResponse?.item?.address?.state?.name);
      setSelectedState(profileLiteResponse?.item?.address?.state);
      setSelectedDob(profileLiteResponse?.item?.dateOfBirth);
      setAvatarId(profileLiteResponse?.item?.avatar);

      setLoader(false)
    }
    if (!profileLiteResponse?.item?.address?.state) {
      let resState = await getAllStateList({
        skip: 0,
        limit: 100,
        sortBy: "name",
        sort: "asc",
      });
      if (resState) {
        setAllStates(resState?.list);
      }
    }

    let avatars = await getAllFreeAvatar({
      skip: 0,
      limit: 50,
      allAvatar: true,
    });
    if (avatars) {
      let temp = [];
      avatars?.list?.map((item, index) => {
        temp.push({
          logo: item?.img?.default,
          name: item?.name,
          id: item?._id,
          isFree: item?.isBuy,
          isPremium: item?.isPremium
        });
      });
      setAllAvatars(temp);
    }
  };

  return (
    <div
      className="inner-wrap custom_scroll_leader"
      style={{ paddingTop: "1em", overflow: "auto" }}
    >
      {loader && <Loader />}
      <ToastContainer />
      <div className="profile_insights_main">
        <div className="header">
          <div className="back-btn">
            <Link to="/profile">
              <img src={Previous} alt="" />
            </Link>
          </div>
          <h2>{t("Profile.edit_profile")}</h2>
          <div className="wallet-btn"></div>
        </div>

        <div className="profile_insights_1">
          <div className="profile_edit_top">
            <div className="profile_edit_top_inner">
              <div className="row">
                <div className="col-4 col-md-4 pr-0">
                  <div className="form-group">
                    <label>{t("Profile.country_code")}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        userData?.phoneCode
                          ? userData?.phoneCode
                          : JSON.parse(localStorage.getItem("userCountryObj"))
                            ?.dialingCode
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-8 col-md-8">
                  <div className="form-group">
                    <div className="d-flex flex-row align-items-center">
                      <label>{t("Profile.mobile_number")}</label>
                      {userData?.isMobileVerified && (
                        <img
                          className="ml-2 verifiedUserIcon align-middle"
                          src="/images/round_qualified.png"
                          alt="qualified"
                        />
                      )}
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      onChange={(e) =>
                        setUserData({ ...userData, phone: e.target.value })
                      }
                      value={userData?.phone}
                      disabled={userData?.isMobileVerified}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>{t("Profile.date_of_birth")}</label>
                <div
                  className="dobns_input_component"
                  style={{
                    backgroundColor: `${userData?.dateOfBirth ? "#e8e9eb" : "transparent"
                      }`,
                  }}
                >
                  <input
                    className="dobns_input"
                    type="date"
                    max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                    placeholder={t('error_Select_date_of_birth')}
                    // onChange={(e) => { this.setState({ dob_value: e.target.value, }) }}
                    onChange={(e) =>
                      // setUserData({ ...userData, dateOfBirth: e.target.value })
                      setSelectedDob(e.target.value)
                    }
                    value={getHTMLdateFormat(selectedDob)}
                    disabled={userData?.dateOfBirth}
                  />
                  <div className="dobns_icon">
                    <img src={dateIcon} alt="" />
                  </div>
                </div>
              </div>
{
  userData?.phoneCode == "+91" && 
              <div className="form-group">
                <label>{t('Profile.state')}</label>
                {/* <div className="dobns_input_component"> */}
                <div className="">
                  <input
                    className="dobns_input form-control"
                    type="text"
                    disabled={userData?.phoneCode == "+91" ? getOptions : true}
                    value={(getOptions && getOptions) || selectedState?.name}
                    placeholder={t('error_Select_state')}
                    // readOnly
                    onClick={() => setTagStatePopup(true)}
                  />

                  <div className="dobns_icon">
                    {/* <img src={stateIcon} alt="" /> */}
                  </div>
                </div>
              </div>
}

              {userData?.phoneCode == "+91" && tagStatePopup && !getOptions && (
                <div className="dobns_state_div">
                  <div className="dobns_state_popup">
                    <div className="dobns_header">
                      <div className=""></div>
                      <div className="dobns_header_text">{t('Profile.select_state')}</div>
                      <div className="dobns_cancel_icon">
                        <img
                          src={Cancel}
                          onClick={() => setTagStatePopup(false)}
                        />
                      </div>
                    </div>

                    <div className="dobns_state_body">
                      <div className="dobns_state_body_scrollable_component">
                        <div
                          className="dobns_state_bodyee"
                          style={{ width: "100% !important" }}
                        >
                          {allStates?.map((state, index) => (
                            <div
                              className={`dobns_state_list_text ${state?._id === selectedState?._id
                                ? "selected"
                                : ""
                                }`}
                              key={state?._id}
                              value={state?._id}
                              selected={userData.address.state ? state?._id === userData.address.state._id : null}
                              onClick={() =>
                                handleStateClick({ type: "state", ...state })
                              }
                            >
                              {state?.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* );
})} */}
              <div className="form-group">
                <div className="d-flex flex-row align-items-center">
                  <label>{t('Profile.email')}</label>
                  {userData?.isEmailVerified && (
                    <img
                      className="ml-2 verifiedUserIcon align-middle"
                      src="/images/round_qualified.png"
                    />
                  )}
                </div>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                  value={userData?.email}
                  disabled={userData?.isEmailVerified}
                />
              </div>
              <div className="form-group">
                <label>{t('Profile.gamerji_Username')}</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setUserData({ ...userData, gamerjiName: e.target.value })
                  }
                  value={userData?.gamerjiName}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ textAlign: "center", color: "white" }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {t("Profile.avatar")}
            </p>
            <div className="scrolling-wrapper row flex-row flex-nowrap">
              {allAvatars?.map((item, index) => {
                return (
                  <div className="col-5 pb-3" key={index}>
                    <div
                      className={`item p-2 ${item.id === avatarId ? "activeAvatar" : ""
                        }`}
                      onClick={() => {
                        if (premiumUser) {
                          setAvatarId(item?.id);
                        }
                        else if (item?.isFree) {
                          setAvatarId(item?.id);

                        } else {
                          // console.log("locked", item.id, avatarId);
                        }
                      }}
                      style={{ position: "relative" }}
                    >
                      {item.id === avatarId ? (
                        <i className="fa fa-check-circle" />
                      ) : premiumUser && item?.isPremium ? (
                        <>
                          <img src={PremiumBlack} className="ps_avatar_img" />{" "}
                        </>
                      ) : (
                        !item?.isFree ?
                          <div className="ps_avatar_op">
                            <img
                              src={Lock}
                              style={{
                                width: "40px",
                                height: "40px",
                                position: "absolute",
                              }}
                            />
                          </div> : ""
                      )}

                      <img src={url.imageUrl + item?.logo} />
                    </div>
                    <p
                      className="mt-2"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {item?.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          {
            <div className="ps_summary">
              {premiumUser ? (
                <p>
                  <Trans
                    i18nKey="Profile.you_have_unlock_avatar"
                    t={t}
                    components={[<Link className="link" to={routehelp.premiumSubscription}> {t('premium.gamerji_premium')} </Link>]}
                  />
                </p>
              ) : (
                <p>
                  <Trans
                    i18nKey="Profile.unlock_avatar"
                    t={t}
                    components={[<Link className="link" to={routehelp.premiumSubscription}> {t('premium.gamerji_premium')} </Link>]}
                  />
                </p>
              )}
            </div>

          }
          <div class="d-flex justify-content-center align-items-center">
            <button
              className="btn main-btn signup_button"
              style={{
                width: "90%",
                // marginLeft: "20px",
                marginTop: `${premiumUser ? 0 : "30px"}`,
              }}
              onClick={() => onSubmit()}
            >
              {t("Profile.save")} <img src={rightArrow} alt="" className="mCS_img_loaded" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileEdit;
