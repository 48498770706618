// const base_url = "http://35.154.8.149/leagueopsv2api/";
// const client_base_url = "https://web.gamerji.com/";
// const client_base_url = "https://gamerji-pwa-staging.gamerji.cloud/";
// const client_base_url = "https://gamerji-pwa-testing.gamerji.cloud/";
//const client_base_url = "http://localhost:3006/"; //##localhost## comment by kuldeep
// const base_url = "https://api.gamerji.tech/";
//const base_url = "https://gamerji-api-staging.gamerji.cloud/";
//https://www.gamerji.me:9171/api/
// const base_url = "http://13.127.144.132/leagueopsv2api/";//https://www.gamerji.me:9171/api/
// export const base_url = "https://gamerji-api-staging.gamerji.cloud/";//stagging
// export const base_url = "https://gamerji-api-testing.gamerji.cloud/"; //testing
// export const base_url = "http://localhost:9171/"; //##localhost## comment by kuldeep
// const base_url = "https://api.gamerji.tech/"; //prod

// export const SAAS_API_URL = 'http://35.154.8.149/saasapidiamond/api/' //local
// export const SAAS_API_URL = 'https://gamerji-api-by-eaas-staging.gamerji.cloud/' //staging
// export const SAAS_API_URL ="https://gamerji-api-by-eaas-testing.gamerji.cloud/"; //testing
// export const SAAS_API_URL = 'http://localhost:9155/' //##localhost## comment by kuldeep

// export const SAAS_API_URL = 'https://api-by-eaas.gamerji.tech/' //prod
//const base_url = "http://35.154.8.149/leagueopsv2api/";
//const base_url = "http://localhost:9171/leagueopsv2api/";//https://www.gamerji.me:9171/api/

const client_base_url = process.env.REACT_APP_CLIENT_BASE_URL;
export const base_url = process.env.REACT_APP_BASE_URL;
export const SAAS_API_URL = process.env.REACT_APP_SAAS_API_URL;
const s3_base_url = "https://gamerji-uploads.s3.amazonaws.com";

export const eassUrl = "https://eaas-staging-api.gamerji.cloud/api/";

export const companyId = "634082e312598b8e50ef0d78"

const url = {
  siteUrl: client_base_url,
  apiUrl: base_url + "api/",
  accountUrl: base_url + "api/account/",
  contestUrl: SAAS_API_URL + "api/contest/",
  contestOldUrl: base_url + "api/contest/",
  authUrl: base_url + "api/auth/",

  publicUrl: base_url + "api/public/",
  eventUrl: base_url + "api/events/",
  eventListUrl: base_url + "api/events/",
  bannerUrl: base_url + "api/banners/",
  usersUrl: base_url + "api/users/",
  gamesUrl: SAAS_API_URL + "api/game/",
  gamesWebUrl: SAAS_API_URL + "api/webapp/games/",

  gamesTypesUrl: SAAS_API_URL + "api/gameType/",
  uploadUrl: base_url + "api/common/upload-image",
  uploadVideoUrl: base_url + "api/common/upload-video",
  //imageUrl: base_url + "uploads/",+=
  imageUrl: s3_base_url + "/",
  returnUrl: client_base_url + "payment-status/",
  returnMobileUrl: client_base_url + "pg-payment-status/",
  returnWebUrl: client_base_url + "pg-payment-status-web/",
  saasApiUrl: SAAS_API_URL + "api/",
  v2Url: "api/v2/",
  webAppUrl: SAAS_API_URL + "api/webapp/",
  webAppApiUrl: base_url + "api/webapp/",
};

export default url;
