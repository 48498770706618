import React, { Fragment, useEffect, useState, useRef } from "react";
import AllGames from "../assets/images/all-games.svg";
import GameIconSmall1 from "../assets/images/game-icon-small1.svg";
import GameIconSmall2 from "../assets/images/game-icon-small2.svg";
import { Link } from "react-router-dom";
import {
    bannerAdLog,
    homebanner
} from "../apis";
import { convertNumberToMillions, dateFormat, kFormatter } from "../component/common";
import url from "../constants/url";
import Loader from "../component/loader";
import { useTranslation, Trans, withTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { bannerRedirection } from "../component/common";

function Banner(props) {
  const [showBanner, setShowBanner] = useState(!props.value);
    
    const [allData, setAllData] = useState([]);
    const carouselRef = useRef(null);
    const [loader, setLoader] = useState(false);
    // const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        // console.log("View carouselRef called ",carouselRef.current)
        // Cleanup function to stop the carousel when the component unmounts
        // return () => {
        //   setShowBanner(false)
        // };
    }, []);

    useEffect(() => {
        fetchData();
        // props.setShowBanner(true)
        return ()=>{
            // console.log("resturn",showBanner,carouselRef.current)
            carouselRef.current = null
                setShowBanner(false)
        }
    }, [props.showBanner]);

    const fetchData = async () => {
        setLoader(true);
    
        let data = {
            skip: 0,
            limit: 100,
        };
        
        let response = await homebanner(data); // comment by jaimin 
        if (response) { // comment by jaimin 
            let temp = [];  // comment by jaimin 
            response?.list?.length && setAllData(response?.list); // comment by jaimin 
            setLoader(false);
        } // comment by jaimin
    };

    const onChangeBannerAdOwlCarousel = (e) => {    
            // console.log("View: showBanner", showBanner,carouselRef.current)
            if (e.item.index > 0 && e.item.index <= allData.length) {
            let data = {
                type: "1",
                bannerAd: allData[parseInt(e.item.index) - 1]._id,
                platform: "webapp",
            }
            // console.log('View: ', data);
            if(carouselRef.current != null){
                // console.log("Calling View: showBanner", showBanner,carouselRef.current)
                bannerAdLogAPICall(data);
            }
        }
    };
    
    const onClickBannerAdOwlCarousel = (value) => {    
        let data = {
            type: "2",
            banner: value._id,
            platform: "webapp",
        }

        bannerAdLogAPICall(data);
        if (value.url === "" || value.url === null) {
            var url_redirection = bannerRedirection(value);
            window.location.href = url_redirection;
        } else {
            window.open(value.url, '_blank');
        }
    };
    
    const bannerAdLogAPICall = async (data) => {
        bannerAdLog(data);
    }
    // console.log(window.location.pathname)
    return (
        <>
            {loader ? <Loader /> : ""}
            {/* Banner */}
            {/* ******************** comment by jaimin start ******************* */}
                <div className="">
                    <OwlCarousel
                        ref={carouselRef}
                        className="main-slider owl-carousel owl-theme"
                        autoWidth={false}
                        items={1}
                        center={true}
                        loop={true}
                        margin={0}
                        stagePadding={25}
                        autoplay={true}
                        autoplayTimeout={5000}
                        dots={false}
                        onChange={(e) => onChangeBannerAdOwlCarousel(e)}
                    >
                        {allData?.map((item, index) => {
                            return (
                                <div className="item" key={index} style={{ cursor: 'pointer', }}
                                    onClick={() => onClickBannerAdOwlCarousel(item)}
                                >
                                    <img src={url.imageUrl + item?.img?.default} alt="" />
                                </div>
                            );
                        })}
                    </OwlCarousel>
                </div>
            {/* ******************** comment by jaimin end ******************* */}
        </>
    )
}
export default Banner;