const routehelp = {
  default: "/",
  signin: "/sign-in",
  register: "/register",
  // registerEmail: "/register-email/:id",
  registerEmail: "/register-email",
  // signinEmail: "signin-email/:id",
  signinEmail: "/signin-email",

  registerCampaign: "/register/:id",
  signinCampaign: "/sign-in/:id",
  signinInternational: "/login-international",
  signup: "/sign-up",
  otp: "/otp",
  otpemail: "/otpemail",
  otpemailregister: "/otpemailregister",
  more: "/more",
  addbalance: "/add-balance",
  coinstore: "/coin-store",
  myRecentTransactions: "/my-recent-transactions",
  mycontest: "/my-contest",
  privateScrims: "/private-scrims",
  howtocreateScrimbs: "/how-to-create-scrims",
  scrimLeaderBoard: "/manage-scrim/:id",
  createScrim: "/create-scrim",
  scrimSummary: "/scrim-summary",
  scrimDetail: "/scrim-details/:id",
  editScrim: "/edit-scrim/:id",

  bank: "/bank",
  account: "/account",
  changepassword: "/change-password",
  contestdetails: "/contest-details/:id",
  contestdetailsold: "/contestdetails/:id",
  tournamentdetail: "/tounament-details/:id",
  contestlist: "/contest-list",
  customercare: "/customer-care",
  dailyloginrewards: "/daily-login-rewards",
  dobnstate: "/dob-n-state",
  entercarddetails: "/enter-card-details",
  game: "/game/:id",
  games: "/games/:id",
  gamesCategories: "/games-categories/:id",
  gameType: "/game-type/:id",
  directLinkpage: "/direct-link",
  leaderboard: "/leader-board",
  premiumSubscription: "/premium-subscription",
  searchuser: "/search-user",
  legality: "/legality",
  monthlychampionship: "/monthly-championship",
  pan: "/pan",
  recenttransactions: "/recent-transactions",
  ticketno: "/ticket-no",
  tournaments: "/tournament/:id",
  singlegame: "/tournaments/:id",
  coinStore: "coin-store",
  paymentOptions: "/payment-options/:id",
  paymentOptionsWeb: "payment-options-web/:id",
  paymentStatus: "/payment-status/:id",

  profile: "/profile",
  profile1: "/profile1",
  userProfile: "/user-profile/:id",
  terms: "/terms",
  privacy: "/privacy",
  gamerjipoints: "/gamerji-points",
  profileInsights: "/profile-insights/:id",
  profileEdit: "/profile-edit",
  collegiateDetails: "/collegiate-details",
  friends: "/friends",
  worldOfEsports: "/world-of-esports",
  referAFriend: "/refer-a-friend",
  howToPlay: "/how-to-play",
  //paymentGateway: "/payment-gateway",
  paymentGateway: "/payment-gateway/:id",
  paymentGatewayNew: "/payment-gateway-new/:id",
  pgPaymentStatus: "/pg-payment-status/:id",
  paymentGatewayWeb: "/payment-gateway-web/:id",
  pgPaymentStatusWeb: "/pg-payment-status-web/:id",
  withdrawal: "/withdrawal",
  withdrawalWeb: "/withdrawal-web",
  verifyMobileEmail: "/verify-mobile-email",
  myVideos: "/my-videos",
  woeallbloags: "/woe-all-blogs",
  woeallvideos: "/woe-all-videos",
  woeallprofile: "/woe-all-profile",
  woeallindivisualblog: "/woe-individual-blog/:id",
  phoneStatus: "/response/:id",
  phonepeStatus: "/status/:id",
  scrimCreate: "/create-scrim-again",
  xsollPayment: "/xsolla-coin-store",
  xsollaStatus: "/xsolla-status",
  airpayStatus: "/airpay-status",
  xsollaSubscriptionStatus: "/xsolla-subscription-status",
  adPage: "/ad-page",

  //auction
  cricketAuctionList:'auction/cricket-list',
  cricketAuctionListDetails:'auction/cricket-list-details',
  viewLeaguesAuctionListDetails:'auction/view-leagues-list-details',
  createPrivate:'auction/create-private',
  createPrivateDetails:'auction/create-private-details',
  playerJoinedDetails:'auction/player-joined-details',
  enterAuction:'enter-auction',
  enterAuctionTeam:'enter-auction-team',
  enterAuctionSummary:'enter-auction-summary',
  enterAuctionRemaining:'enter-auction-remaining',
  auctionTimer:'auction/auction-timer',
  auctionSettings:'auction-settings',
  enterTeams:'auction/enter-teams',
  enterTeamsEdit:'auction/enter-teams-edit',
  myLeague:'auction/my-league',
  moreFaq:'auction/more-faq',
  auctionFaq:'auction/action-faq',
  auctionFantasyPoint:'auction/action-fantasy-point',
  auctionHowToJoin:'auction/action-how-to-join',
  payUStatus:'payment-payu-status',













};

export { routehelp };
