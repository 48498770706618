import { goback } from "../../component/common";
import Gamer from "../../assets/images/gamer.svg";
import Podium from "../../assets/images/gamer.svg";
import Previous from "../../assets/images/previous.svg";
import R5 from "../../assets/images/r-5.svg";
import { Rate } from "../../component/ScrimComponent/Rate";
import { useEffect } from "react";
import { getUserDetail, viewScrimById } from "../../apis";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ScrimlistSingleCard } from "../../component/ScrimComponent/ScrimSummaryCard";
import Players from "../../component/ScrimComponent/Players";
import { ManageScrimRules } from "../../component/ScrimComponent/ManageSrimRules";
import url from "../../constants/url";
import Loader from "../../component/loader";
import PremiumLogoBlack from "../../assets/images/GoldPremium.png";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'
import { constants } from "../../constants/constants";

export const ScrimDetailpage = () => {
  const { t } = useTranslation();
  const currentLang = Cookies.get('i18next') || 'EN'

  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [userDetails, setuserDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    fetchData();
    fetchuser();
  }, []);

  const savedRate = localStorage.getItem("userRating");
  ////console.log(savedRate);
  var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
  //console.log(user_data?.item?.avatar?.img?.default);
  const showSuccess = (error) => {
    toast.success(error, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showError = (err) => {
    //console.log("showeeror", err);
    toast.error(err, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const fetchData = async () => {
    let res = await viewScrimById({
      id: params?.id,
    });
    //console.log(res);

    if (res.success) {
      //console.log(res.data[0]?.param);
      setData(res.data[0]?.param);
    } else {
      showError(res.data[0]?.msg);
    }
  };
  const fetchuser = async () => {
    setLoader(true);
    var res = await getUserDetail({
      scrimId: params?.id,
      userId: JSON.parse(localStorage.getItem(constants.profileLite))?.item.user_id,
      // "rating":savedRate
    });
    // res.data.rating = savedRate
    //console.log(res);
    if (res?.success) {
      setuserDetails(res?.data);
    } else {
      showError(res.data[0].msg);
    }
    setLoader(false);
  };

  // //console.log(userDetails)
  if (loader) return <Loader />;
  return (
    <>
      <ToastContainer />
      <div className="inner-wrap" id="my_contest_main_inner">
        <div className="header">
          <div className="back-btn">
            <a onClick={() => navigate(-1)}>
              <img src={Previous} alt="" />
            </a>
          </div>
          <h2> {t("private_contest.private_contest")}</h2>
          <div className="wallet-btn">
            {/* <Link to="/">
              <img src={Wallet} alt="" />
              Wallet
            </Link> */}
          </div>
        </div>
        <div className="body mCustomScrollbar _mCS_1 bottom-btn-body mycontest_main pt-0 padding-top">
          <div className="all_sub_screens_div_related_to_ads">
            <div className="list-group main-tab" id="list-tab" role="tablist">
              <a className="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" href="#list-1" onClick={() => setActiveTab(0)}>
                {t("crm.details")}
              </a>
              <a className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#list-2" onClick={() => setActiveTab(1)}>
                {t("contest_details.players")}
              </a>
              <a className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#list-3" onClick={() => setActiveTab(2)}>
                {t("contest_details.rules")}
              </a>
            </div>

            <div className="tab-content " id="nav-tabContent">
              <div className="tab-pane active " id="list-1">
                {/* <JoinedScrimCard /> */}
                <ScrimlistSingleCard data={data} />
                <div className="margin-top3">
                  <div className="player-icons-captain">
                    <div className="row no-gutters">
                      <div className="col-8">
                        <div className="item" style={{ width: "95px" }}>
                          <div className="icon">
                            <img src={Gamer} alt="" />
                          </div>
                          {t("contest_details.username")}
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="item">
                          <div className="icon"></div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="item">
                          <div className="icon">
                            <img src={Podium} alt="" />
                          </div>
                          {t("contest_details.rank")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="player_captain contest mb-3">
                    <div className="item">
                      <div className="col-8">
                        <div className="row m-0 p-0">
                          <div className="icon">
                            <img
                              src={url.imageUrl + userDetails?.levels?.featuredImage?.default}
                              alt=""
                              style={currentLang?.toLowerCase() == "ar" ? { width: "40px", marginLeft: "10px" } : { width: "40px", marginRight: "10px" }}
                            />
                          </div>
                          <div className="d-flex my-auto h-100" style={{ flexDirection: "column" }}>
                            {/* <b style={{ fontSize: '16px', }}>{captainData?.inGameUserName}</b> */}
                            <b style={{
                              fontSize: "16px", display: 'block', width: '70px', whiteSpace: "nowrap", overflow: "hidden",
                              textOverflow: "ellipsis", marginTop: "0.5rem"
                            }}>{userDetails.gamerJiName}</b>
                            {JSON.parse(localStorage.getItem("premiumUser")) ? (
                              <div className="ps_player_front">
                                <img style={{ width: "28px", height: "25px", marginLeft: "20px" }} src={PremiumLogoBlack} />{" "}
                              </div>
                            ) : (
                              ""
                            )}
                            <span style={{ fontSize: "10px" }}>{userDetails.usersInGameName}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center" style={{ height: "40px" }}>
                        <b className="">{userDetails?.rank ? userDetails?.rank : "-"}</b>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {//console.log(">>>>", userDetails)} */}
                <Rate id={params?.id} rating={userDetails?.rating?.rate} />
              </div>
              <div className="tab-pane  " id="list-2">
                {activeTab === 1 && <Players />}
              </div>
              <div className="tab-pane " id="list-3">
                {activeTab === 2 && <ManageScrimRules />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
