import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import TagManager from "react-gtm-module";
import { ProfileProvider } from "./component/ProfileComponent/ProfileContext";
import './utils/Translations/i18n'
import Loader from "./component/loader";

const tagManagerArg = {
  gtmId: "G-NWVPWWCL3N",
};
TagManager.initialize(tagManagerArg);
ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback={<Loader />}>
      <Router>
      <ProfileProvider>
        <App />
        </ProfileProvider>
      </Router>
      </Suspense>
  // </React.StrictMode>
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
